import { Text } from "@clipboard-health/ui-react";
import { Stack } from "@mui/material";
import { type Agent } from "@src/appV2/Agents";
import { type Facility } from "@src/appV2/Facilities/types";
import { calculateDistanceToFacilityInMiles } from "@src/appV2/Facilities/utils";
import pluralize from "pluralize";

interface FacilityDistanceProps {
  agent: Agent;
  facility: Facility;
}

export function FacilityDistance(props: FacilityDistanceProps) {
  const { agent, facility } = props;

  const { distanceInMiles } = calculateDistanceToFacilityInMiles(agent, facility);

  return (
    <Stack direction="row" alignItems="center">
      <Text variant="body2">
        {distanceInMiles.toFixed(1)} {pluralize("mile", distanceInMiles)} away
      </Text>
    </Stack>
  );
}
