import { Text } from "@clipboard-health/ui-react";
import { type Agent } from "@src/appV2/Agents";
import { utcToZonedTime } from "date-fns-tz";
import pluralize from "pluralize";

function getTimezoneDifferenceInHours(tz1: string, tz2: string): number {
  const now = new Date();

  // Convert the current time to each of the timezones
  const timeInTZ1 = utcToZonedTime(now, tz1);
  const timeInTZ2 = utcToZonedTime(now, tz2);

  // Calculate the difference in hours
  const differenceInMs = timeInTZ1.getTime() - timeInTZ2.getTime();
  return differenceInMs / 1000 / 60 / 60;
}

interface ShiftTimezoneProps {
  timeZone?: string;
  viewer: Agent;
}

export function ShiftTimezone(props: ShiftTimezoneProps) {
  const { timeZone = "", viewer } = props;

  const timezoneDifferenceInHours = getTimezoneDifferenceInHours(timeZone, viewer.tmz);

  if (timezoneDifferenceInHours === 0) {
    return null;
  }

  return (
    <Text
      sx={{
        background: "#D88C33",
        borderRadius: "4px",
        color: "#fff",
        padding: "1px 4px",
        fontWeight: 600,
      }}
    >
      {timeZone} ({Math.abs(timezoneDifferenceInHours)} {pluralize("hr", timezoneDifferenceInHours)}
      {timezoneDifferenceInHours > 0 ? " later" : " earlier"})
    </Text>
  );
}
