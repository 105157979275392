import { InternalLink, Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import QuestionAnswerRoundedIcon from "@mui/icons-material/QuestionAnswerRounded";
import { Badge, Stack } from "@mui/material";
import { type Agent } from "@src/appV2/Agents";
import { type Facility } from "@src/appV2/Facilities/types";
import { formatUsPhoneNumber } from "@src/appV2/lib";
import { GRAY_00 } from "@src/appV2/lib/Theme/constants";

import { FacilityDistance } from "./FacilityDistance";

interface FacilityDetailsProps {
  agent: Agent;
  facility: Facility;
  chatChannels: SendBird.GroupChannel[];
  isExtraDetailsEnabled?: boolean;
  onClickChatButton: () => void;
}

export function FacilityDetails(props: FacilityDetailsProps) {
  const { agent, facility, chatChannels, isExtraDetailsEnabled = true, onClickChatButton } = props;

  const facilityFullAddress = facility.fullAddress?.formatted;
  const facilityPhone = isDefined(facility.phone) ? formatUsPhoneNumber(facility.phone) : undefined;

  const currentChannel = chatChannels.find(
    (channel) => channel.url === `${facility?.userId ?? ""}_${agent.userId}`
  );

  const unreadMessageCount = currentChannel?.unreadMessageCount ?? 0;

  return (
    <Stack spacing={0.25}>
      <Stack direction="row" alignItems="start" justifyContent="space-between" spacing={2}>
        <Text
          variant="body2"
          color={GRAY_00}
          sx={{
            fontWeight: 600,
          }}
        >
          {facility.name}
        </Text>
        {facility.userId && (
          <InternalLink
            aria-label="Facility chat"
            to={`/home/account/chat/${facility.userId}`}
            onClick={(event) => {
              event.stopPropagation();
              onClickChatButton();
            }}
          >
            <Badge color="error" badgeContent={unreadMessageCount} variant="dot">
              <QuestionAnswerRoundedIcon sx={{ color: (theme) => theme.palette.grey[900] }} />
            </Badge>
          </InternalLink>
        )}
      </Stack>
      {isExtraDetailsEnabled ? (
        <>
          {isDefined(facilityFullAddress) ? (
            <Text variant="body2">{facilityFullAddress.replace(", USA", "").trim()}</Text>
          ) : null}

          <Stack spacing={0.5}>
            <FacilityDistance agent={agent} facility={facility} />
            {isDefined(facilityPhone) ? <Text variant="body2">{facilityPhone}</Text> : null}
          </Stack>
        </>
      ) : null}
    </Stack>
  );
}
